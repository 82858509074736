import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Subscribe from "../components/subscribe"
import { rhythm } from "../utils/typography"

class NewsLetter extends React.Component {
  render() {
    const { data } = this.props
    const social = data.site.siteMetadata.social
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle} social={social}>
        <SEO
          title="Hi I'm Nishith 👋"
          keywords={[`blog`, `gatsby`, `javascript`, `react`, `newsletter`, `react native`,
            `nishith`, `nishith studio`, `reactjs`, `npatel`, `pnishith`, `tasteoftechzone`,
            `writer`, `coder`, `learn to code`, `nishith patel`, `nishithpatel`, `nishithpatel react`]}
          slug={"/newsletter"}
        />
        <Subscribe />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <div key={node.fields.slug} className="post-list">
              <h4
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link to={node.fields.slug}>
                  {title}
                </Link>
              </h4>
              <div style={{
                marginBottom: rhythm(1 / 8),
              }}>
                <small className="dateMinClass">{node.frontmatter.date} &nbsp; • {node.timeToRead} min read</small>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default NewsLetter

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          twitter
          facebook
          github
          instagram
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
